/********* Comet business *********/
function clearComet(random_comet_id) {
    console.log(random_comet_id);
    $('#' + random_comet_id).removeClass('flying');
}

function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
}


export default function cometFall(){
    var comet_shift_x = [-170, -160, -150, -180, -145, -190];
    var comet_shift_x_mobile = [-300, -300, -300, -300, -300, -300];
    var comet_shift_y = [0, -5, 15, -5, 3, 5];
    var comet_shift_y_mobile = [-10, 0, -20, +10, +30, +40];
    var comet_max_scale = [0.8, 1, 1.1, 1.2, 1.25, 0.9];
    var comet_timing = [1, 1, 1.3, 1.05, 1, 1.2];
    
    var tl = new TimelineLite();
    let random_comet = $(".comet:not(#" + window.last_fallen_comet + "):not(.flying)").get().sort(function () {
        return Math.round(Math.random()) - 0.5;
    }).shift(); 

    if (random_comet !== undefined){
    random_comet = $('#' + random_comet.id);
    
    let random_comet_id = random_comet.attr('id');
    window.last_fallen_comet = random_comet_id;
    let random_comet_d = random_comet.data('default_d');
    var coords = random_comet.data('default_d').split(',');

    let rand_time_scale = getRandomInt(6); // 
    let rand_scale = comet_max_scale[rand_time_scale];
    let rand_x_shift = comet_shift_x[getRandomInt(6)];
    let rand_y_shift = comet_shift_y[getRandomInt(6)];
    let rand_timing = comet_timing[rand_time_scale]*1.4; //Correct timing
    //let rand_timing = 1.5;
    
    if (random_comet.data('xshift')) rand_x_shift = random_comet.data('xshift');
    let comet_d_m_x = Math.ceil(coords[0]) + rand_x_shift;
    let comet_d_m_y = Math.ceil(coords[1]) + rand_y_shift;
    var new_comet_d = "m " + comet_d_m_x + ", " + comet_d_m_y + " c 0.7,0.7 -126.8,129.2 -132.4,134.8 -5.6,5.6 -10.7,9.6 -11.4,9 -0.7,-0.7 3.3,-5.8 9,-11.4 5.6,-5.6 134.2,-133 134.8,-132.4 z";

    random_comet.addClass('flying');
    tl.set(random_comet, { y: 0, x: 0, autoAlpha: 0, scale: 0.5, attr: { d: new_comet_d}})
        .to(random_comet, rand_timing, { y: 0, x: 0, autoAlpha: 0.8, scale: 0.8, ease: Linear.easeNone})
        .to(random_comet, rand_timing, { y: 0, x: 0, autoAlpha: 0.3, scale: 1.1, ease: Linear.easeNone })
        .to(random_comet, rand_timing+0.1, { y: 0, x: 0, autoAlpha: 0, scale: 1.45, ease: Linear.easeNone  })
        .set(random_comet, { y: 0, x: 0, autoAlpha: 0, scale: 0.5, attr: { d: new_comet_d, class: "comet" }})
    }
}
/********* End *********/