import './style.scss';
var fs = require('fs');
var $ =  require('jquery');
import { TweenLite } from "gsap";

window.$ = window.jQuery = $;
var owlCarousel = require('owl.carousel');
var CountUp = require('countup.js');
const bodyScrollLock = require('body-scroll-lock');

const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;
//import * as basicScroll from 'basicscroll';
//var office_svg =  require('./images/office.svg');



import parallax from './modules/parallax.js';
import introGo from './modules/introGo.js';
import cometFall from './modules/comets.js';


//import desk_lamp from './modules/desk_lamp.js';
import cogs from './modules/svg/cogs.js';

import android from './modules/svg/android.js';
import carbody from '/images/portfolio/carbody.json';
//import mitsu from './images/portfolio/mitsu.json';
import vw from './images/portfolio/vw.json';

import {tableRise} from './modules/office.js';


const astro = fs.readFileSync('./images/astro.svg', 'utf8');

const iMac = fs.readFileSync('./images/devices/iMac.svg', 'utf8');
const iPhone = fs.readFileSync('./images/devices/iPhone.svg', 'utf8');
const ipad = fs.readFileSync('./images/devices/ipad.svg', 'utf8');
const ipad_mini = fs.readFileSync('./images/devices/ipad_mini.svg', 'utf8');
const laptop = fs.readFileSync('./images/devices/laptop.svg', 'utf8');
const laptop_small = fs.readFileSync('./images/devices/laptop_small.svg', 'utf8');

const office_svg = fs.readFileSync('./images/office.svg', 'utf8');

$(".iMac").html(iMac);
$(".iPhone").html(iPhone);
$(".ipad").html(ipad);
$(".ipad_mini").html(ipad_mini);
$(".laptop").html(laptop);
$(".laptop_small").html(laptop_small);
$(".pos0 .svg").html(astro);
$("#office").html(office_svg);



$("#carbody").html(carbody.code);
//$(".lamp_holder").html(desk_lamp);
$(".gear_holder > .bigletter").html(cogs);
$(".mitsu_icon").html(vw.code);
$(".android_holder > .bigletter").html(android);
parallax();


const hex = '<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMax slice" viewBox="0 0 348.78 402.65"><defs></defs><title>hex</title><g id="Layer_2" data-name="Layer 2"><g id="hex"><path id="hex-2" class="hex_outer" data-name="hex" d="M348.72,100.61l.06,201.33L174.45,402.65.06,302,0,100.71,174.33,0Z"/><path id="hex-3" data-name="hex" class="hex_inner" d="M338.93,107.62,339,294.94,175,388.64,11,295,11,107.71,174.93,14Z"/></g></g></svg>';



function resize() {
  let ww = $(window).width();
  let wh = $(window).height();
  let head_ratio = 1920/1000;
  let head_height = $(window).height();
    if (ww/wh > head_ratio) { //too wide
     head_height = ww/1.9;   
   }
   $('#head svg').height(head_height);

 }

 $(window).resize(function () {
  resize(); 
});




 /********* Launch header transition *********/
 function bubbles(){
  var tl = new TimelineMax();
  tl.staggerTo('#bubbleGroup circle', 3, {
   attr: {
     cy: 200
   },
   ease:Power2.easeIn,
   repeat: -1
 }, 0.7)
}
/********* End *********/



/********* Android icon animation *********/
function androidAnimation() {
  var animo = new TimelineLite();
  $("#left_hand").attr('d');

  animo
  .from("#left_hand", 1.2, { x: "+=20" })
  .from("#right_hand", 1.2, { x: "-=20" }, "0")
  .from("#droidhead", 1, { y: "+=88" }, "0.2");
    //animo.play();
    console.log(`animo`, animo);
    //window.droid = animo;
  }
  function droidInit(){
    let wh = $(window).height();
    let element = document.getElementById("android_card");
    
    element.addEventListener("transitionend", function(event) {
      let isHovered = $('#android_card').is(":hover");
      if (isHovered){
       $('.android_holder').removeClass('active');
     }
     else {
      $('.android_holder').addClass('active');
    }
  }, false);
  }
  /********* End *********/



  /********* Launch animation *********/
  var intro_parallax = introGo();


/*  $('.svg').on('click', function () {
        //tl.reversed() ? tl.play() : tl.reverse();
        intro_parallax.reversed ? intro_parallax.play() : intro_parallax.reverse();
        //introGo();
         //tl.restart();
        //console.log("tl", intro_parallax);
      })*/

  function launch(){   
    let ww = $(window).width();
    let wh = $(window).height();
    $('.preloader').html('');
    $('.preloader').addClass('hidden');
    intro_parallax.play();

    setTimeout(droidInit, 2500);
    $('.comet').each(function () {
      let comet_d = $(this).attr('d');
      let default_d = comet_d.substring(
        comet_d.lastIndexOf("m") + 1,
        comet_d.lastIndexOf("c")
        );
      $(this).attr('data-default_d', default_d);
    });
   setInterval(cometFall, 650);
   bubbles();

   $('.lamp_holder').click(function(event) {
    //lampAnimation()
  });
   //setTimeout(mouse_para, 2000);
   //setTimeout($("html, body").animate({ scrollTop: 0 }, 3000), 2000);
 }
 /********* End *********/




 /*********                  *********/
 /********* Call number counter   *********/
 /*******                      *******/
 function numberCount(param){
  return true;
}  

let counter_options = {
   useEasing: true,
   useGrouping: false,
   separator: '',
   decimal: '',
};

var numbers = document.getElementsByClassName("num_holder");
[].forEach.call(numbers, function (el) {
  var number = el.querySelector('.the_number');
  if (number !== null) {
   let toNum = number.innerHTML;
   el.addEventListener('transitionstart', function() {
    let counter = new CountUp(number.id, 0, toNum, 0, 1.1, counter_options);
    counter.start();
  });
 }
});

/*********                  *********/
/********* End               *********/
/*******                      *******/





/*********                  *********/
/********* Document ready   *********/
/*******                      *******/
$(window).ready(function () {
  var $window = $(window);
  var $elem = $(".dancing");
  var ww = $(window).width();
  var wh = $(window).height();

  /********* Detect if object in viewport *********/
  $(window).on("scroll", function () {
    var st = $(window).scrollTop(); 
    var wh = $(window).height();
    var display_bottom = st+wh;

    if (st == 0) {
      $("html").removeClass("scrolled_down");
    }
    if (st > 0) {
      if (!$("html").hasClass("scrolled_down")){
        //$("html, body").animate({ scrollTop: wh-100 }, 1500);
       
        $("html").addClass("scrolled_down");
      }
      
    }

    $( ".trigger" ).each(function() {
     var subj = $(this);
     var cor = subj.height()/2;
     if ($(this).data('target'))  subj = $($(this).data('target'));
     if ($(this).data('cor'))  cor = $(this).data('cor');
     if (cor == 'height') cor = subj.height();

     var elmnt = $(this).offset();
     var trigger_pos = elmnt.top+cor;

           

            if (trigger_pos <= display_bottom && trigger_pos >= st && !$(this).hasClass("onscreen")){  
            subj.addClass('onscreen');  
              if ($(this).data('call') == 'tableRise') { //Call a function if needed
               // var functionName = getFunctionByName($(this).data('call'));
               //eval($(this).data('call')+'();');
               tableRise();
                //functionName();
              }
              
            }
            else {
              if ($(this).data('getback')){
                subj.removeClass('onscreen');
              }
            }
          });
  });
  /********* End *********/
  /********* End *********/
  /********* End *********/




  /********* Portfoilio animation *********/
  function portfolioSwipe() {        

    var options = {
        useEasing: false,
        useGrouping: false,
        separator: '',
        decimal: '',
    };
    if ($('#carwiki', '.owl-item.active').length ==1) {
      $('#carwiki').removeClass('inactive');
      let length_count = new CountUp('model_length_mm', 0, 4129, 0, 2.2, options);
      let height_count = new CountUp('model_height_mm', 0, 1501, 0, 1.8, options);
      length_count.start();
      height_count.start();
    }
    else {
      $('#carwiki').addClass('inactive');
    }
  }

  //var office_tl = tableRise();

  function portfolioMoving(event){
    if (event.item.index == 0 && $('#office').hasClass("onscreen")){
     tableRise();
   } 
   else {
    $('#dots').removeClass('onscreen');
  } 
}


/*function tableRise(){
  office_tl.play();
}*/

$('#office').click(function(event) {
  $('#dots').removeClass('onscreen');
         tableRise(); //
       });
/********* End *********/

$('#mailform button').click(function(event) {
  event.preventDefault();
  checkSubmit('#mailform');
       });


/********* Carousels *********/
$('.portfolio').owlCarousel({
 items: 1,
 dots: true,
 loop: false,
 onChanged: portfolioMoving,
 onTranslated: portfolioSwipe,
 nav: true,
 navText : ["<i class='icon-angle-left'></i>","<i class='icon-angle-right'></i>"]
});



function hexChange(event){ //Detect rotation angle by drag direcction

  $('.card').removeClass('right');
 if (event.relatedTarget._drag.direction == "right"){
    $('.active .card').addClass('right');
   } 
}


if (ww < 600) { //Hexes are carouseld for Mobile only
 $('.hexes_one').removeClass('hexes');
 $('.hexes_one').addClass('mobile');
 /*var list = $('.hexes_one');
 var listItems = list.children('.card_holder');
 list.append(listItems.get().reverse());*/
 $('.hexes_one').owlCarousel({
   items: 1,
   dots: true,
   loop: false,
   onChanged: hexChange,
   nav: false
 });

}
else {
  $('.card_holder').slice( 3 ).appendTo( ".hex_two" );
         //$('.hex_two').html(hex_scnd);
       }
       /********* End *********/

       resize();
       $(".hex").html(hex);
       launch();
    //setTimeout(launch, 2500);
  });




function checkSubmit(form_id){
  var error = false;
  $(form_id).find('.necesar').each(function(index, el) {
    if ($(this).val() == ''){
      $(this).addClass('non_valid');
      error = true;
    } 
    else  $(this).removeClass('non_valid');
  });

  if (error === false) {
    $.ajax({
        type : 'POST',
        url : $(form_id).attr("action"),
        data : $(form_id).serialize(),
        success: function(data) {
           if (data == 'OK'){
            $('.submit_holder').html('Спасибо, ваше письмо отправлено. Мы вам ответим в течение суток.');
           }   
        }
  });
  }
}