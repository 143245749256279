
/********* Launch header transition *********/
export default function introGo() {
    var tl = new TimelineLite({ paused:true });
    var fast = 3.5;
    var medium = fast+0.5;
    var slow = medium+0.5;
   
    let wh = $(window).height();
    $('.main_header, .submain_header').addClass('onscreen');
    tl
        //.to($("#ground"), intro_para_time, { y: 34, x: 0, scaleY: 1.05 }, "0")
        //.from($("#man"), intro_para_time, { y: -5, scale: 0.94 }, "0")
        .from($(".bottom_part"), slow+0.2, { y: -50}, "0")
        .from($(".navi"), medium, { y: -150}, "0.3")
        /*.from($(".first_section"), intro_para_time, { y: -wh/4}, "0")*/
        .from($(".dark_ground_gs"), slow-0.2, { y: -30, scaleY: 0.55}, "0")
        .from($(".pink_ground_gs"), slow-0.3, { y: 10, scaleY: 0.1}, "0")
        /*.from($(".substrate"), medium, { y: -wh}, "0")*/
        .from($("#scene_holder"), medium, { y: -wh*1.5}, "0")
        .from($(".space_holder"), slow, { y: -50}, "0.5")
        .from($(".rect_holder"), slow, { y: -70}, "0")

        /*.from($("#rect7"), medium, { y: -250}, "0")*/

        .from($(".man_holder"), slow, { y: 27}, "0")
        .from($("#shadow"), medium, { y: -10, scale: 0.8 }, "0")
        .fromTo($("#planet"), fast-1, { y: 100, scale: 1.1, x: 0 }, { y: 0, scale: 1, x: 0 }, "1")
        .from($("#pink_ground"), medium, { y: 13, scaleY: 1.05 }, "0")
        .from($(".first_plan"), slow-0.1, { y: -wh/3 }, "0.1")
        .from($(".second_plan"), slow, { y: -wh/3-130 }, "0.1")
        .from($("#path4353"), 1.5, { y: -25, autoAlpha: 0 })
        .from($("#path4406"), 1, { y: 10, autoAlpha: 0 }, "-=1")
        //.set($('.hex_holder'), {autoAlpha:1});
        //.from($("#comet"), 0.8, { y: -80, x: 100, autoAlpha: 0, scale: 0.5, ease: Linear.easeNone}, "1")
        //.to($('#comet'), 0.4, { y: 60, x: -30, autoAlpha: 0, scale: 0.8, ease: Linear.easeNone }, "2")
        tl.eventCallback("onComplete", afterIntro);
        //tl.delay(2);
     return tl;       
}
/********* End *********/

//var tl = introGo();

function afterIntro(){
    $('.first_info').css('opacity', '1');
    //$('.main_header').addClass('onscreen');
}
