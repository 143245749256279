function lightOn(){
    /*$(".lights").css("visibility", "visible");*/
    //document.getElementById("light").classList.add("onscreen");
    document.getElementById("dots").classList.add("onscreen");
    //document.getElementById("lamp_text").classList.add("onscreen");
}

function shakeMan(){

    document.getElementById("Illustration").classList.add("shake");
}





export let lampAnimation =  function (){
    var lampAction = new TimelineLite({delay:0.6, paused:true});
    
    lampAction
    .from("#liner1", 0.8, 
        {rotation:"-45", transformOrigin:"bottom bottom", ease: Power1.easeOut }, 
       /* {attr:{x1: "580.5", y2: "282"}}, */
        "0")
    .from("#circle1", 0.8, 
        {attr:{cx: "-=24", cy: "+=3"}, ease: Power1.easeOut }, 
        /*{attr:{cx: "581", cy: "246"}},*/
         "0")
    .from("#liner2", 0.8, 
        {rotation:"30", transformOrigin:"left bottom", x: "-=25", y: "+=3", ease: Power1.easeOut }, 
        /*{attr:{x1: "+=2",  x2: "-=7", y2: "-=17"}, y: "-=0", x: "+=12"},*/
         "0")
    .from("#circle2", 0.8,
         {x: "-=10", y: "+=27", ease: Power1.easeOut },  
         /*{x: "+=11", y: "-=16.5"}, */
         "0")
    .from("#joint3", 0.8, 
        {attr:{x2: "-=3", y2: "+=16"}, x: "-=7", y: "+=25", ease: Power1.easeOut }, 
        /*{attr:{x2: "+=11", y2: "-=19"}, x: "+=10", y: "-=16"}, */
        "0")
    .from("#lamphead", 0.8, 
        {rotation:-35, transformOrigin:"top right", x: "-=27", y: "+=41", ease: Power1.easeOut}, 
        /*{rotation:28, transformOrigin:"center center", x: "+=20", y: "-=39"}, */
        "0")
    /*.to("#joint1", 0.7, {rotation:5, x: "+=10", transformOrigin:"center bottom", attr: {"y1": "-=2"}}, "0")*/
    .from("#lamp_button", 0.05, 
        {x: "+=2.2", y: "-=1", onStart: lightOn, ease: Power2.easeIn},
        "-=0.2"
        )
    /*.to("#joint2", 0.25, {rotation:"-=0",  x: "+=0.45", y: "-=0", transformOrigin:"right bottom"}, "0.7")*/
    .timeScale(0.4);

    //lampAction.restart();

   return lampAction;
}




export let officeAnimo = function (direction){
    var tl =new TimelineLite({ paused:true });
    /*console.log("lampa 22", lampa);
    var lamp_tl = lampa();
    console.log("lamp_tl", lamp_tl);
    lamp_tl.time(0);*/
    //lampAnimation.reset();
    //TweenLite.from($(".ceil_lamp > svg"), 1, {y: -210,  onComplete: lightOn});
    //.from($("#Illustration"), 1.3, {x: -700}, "+=0.1")
    //setTimeout(, 1700);
   

    tl
            .from($("#STOL_1_"), 2.2, {strokeDashoffset:-1600}, "-=3")
            .from($("#STOL_1_"), 1, { y: 20}, "-=3")
            
            .from($("#flowerpot"), 1.7, {autoAlpha: 0, y: 110}, "-=2.5")
            .from($("#g1130"), 1.5, { y: 120, scale: 0.6, transformOrigin: "center center"}, "-=2")
            .from($("#g1149"), 1, {scale: 0.6, autoAlpha: 0, transformOrigin: "center center"}, "-=1")
            .from($("#display"), 1.4, {autoAlpha: 0, y: 150}, "-=1.7")//, onComplete: lampAnimation
            .from($(".ceil_lamp > svg"), 1, {y: -210}, "-=2.5")
            .from($("#tablet"), 1, {autoAlpha: 0, y: 80}, "-=2")
            .from($("#desk_lamp"), 1.6, {autoAlpha: 0, y: 150, ease: Linear.easeNone}, "-=2")
            .from($("#cup"), 0.8, {autoAlpha: 0, y: 40}, "-=1.5")
            
            .from($("#clock"), 1.1, {autoAlpha: 0, y: 140}, "-=1.7")
            .from($("#diploma"), 0.9, {autoAlpha: 0, y: 100}, "-=1.8")
            .from($("#webcam"), 1.3, {autoAlpha: 0, y: 70}, "-=0.8")
            ; //, ease: Back.easeOut
            //tl.restart();
   return tl;
}

export let tableRise = function(){
     var lampAction = lampAnimation();
     var tableAction = officeAnimo();
     lampAction.play();
     tableAction.restart();
}