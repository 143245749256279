import * as basicScroll from 'basicscroll';



export default function parallax() {
/********* HEader parallax logic *********/
let ground_shift = 58;
let ground_holder_shift = -30;
let svg_shift = 80;


var w=window,
d=document,
e=d.documentElement,
g=d.getElementsByTagName('body')[0];
let wh =w.innerHeight||e.clientHeight||g.clientHeight;

let substrate_shift = Math.ceil(wh/15);
const instance = basicScroll.create({
    elem: document.querySelector('.man_para_holder'),
    from: 'bottom-bottom',
    to: 'middle-top',

    props: {
        '--dark_ground_transY': {from: '0', to: -ground_shift+'px'},
        '--backup': {from: '0', to: '-500px'},
        '--dark_ground_scaleY': {from: '1', to: '0.45'},
        '--navi_shift': {from: '0', to: '100px'},
        '--heading_shift': {from: '0', to: '14px'},
        '--subheading_shift': {from: '0', to: '12px'},
        '--pink_scaleY': {from: '1', to: '0.1'},
        '--pink_transY': {from: '0', to: '-51px'},
        '--ground_holder': {from: '0', to: -ground_holder_shift+'px'},
        '--man_para_holder': {from: '0', to: '-75px'},
        '--planet_holder': {from: '0', to: '80px'},
        '--heading': {from: '0', to: '60px'},
        '--translate_planet_scaleX': {from: '1', to: '0.93'},
        '--translate_planet_scaleY': {from: '1', to: '0.9'},
        '--translate_planet_r': {from: '1', to: '0.95'},
        '--first_plan_holder': {from: '0', to: '80px'},
        '--second_plan_holder': {from: '0', to: '50px'},
        '--space_transY': {from: '0', to: '200px'},
        
        '--trinity': {from: '0', to: '-30px'},
        '--subtrinity': {from: '0', to: '-20px'},
        '--trinity_bot': {from: '0', to: '-30px'},
        '--substrate': {from: '0', to: -substrate_shift+'px'}
    }
});
instance.start();
/********* End *********/


/********* Subtrinity parallax logic *********/
const para_subtrinity = basicScroll.create({
    elem: document.querySelector('.violet_bg'),
    from: 'top-bottom',
    to: 'top-top',
    props: {
        '--numbers_shift': {from: '0', to: '-30px'},
        '--violet_bg_shift': {from: '0', to: '20vh'}
         //'--hex_holder': {from: '0', to: '-80px'},
        //'--hexes_one': {from: '0', to: '-50px'},
        //'--hexes_two': {from: '0', to: '-70px'} 
    }
});
para_subtrinity.start();
/********* End *********/

/********* Hexes parallax logic *********/
const para_hex = basicScroll.create({
    elem: document.querySelector('.hex_holder'),
    from: 'top-bottom',
    to: 'bottom-middle',
    props: {
        '--flip_position': {from: '0deg', to: '180deg'}
         //'--hex_holder': {from: '0', to: '-80px'},
        //'--hexes_one': {from: '0', to: '-50px'},
        //'--hexes_two': {from: '0', to: '-70px'} 
    }
});
//para_hex.start();
/********* End *********/
  
}
